//
//
//
//
//
//

import MainHeader from '@/headers/MainHeader.vue';

export default {
  name: 'TheTraining',
  components: {
    MainHeader,
  },
  computed: {
    isAuth() {
      return !this.$store.state.token && !this.$route.path.includes('trainings');
    }
  }
}
